import { Link } from "gatsby"
import styled from "styled-components"

const ButtonLink = styled(Link)`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  background: #317ac3;
  color: #fcfdfd;
  font-weight: 500;
  border-radius: 40px;
  letter-spacing: 0.25px;
  align-self: center;
  height: 3.875rem;
  width: 15rem;

  :hover {
    font-weight: 600;
    background: #1b5998;
    text-decoration: none;
    transition: all 0.5s;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 3.625rem;
    width: 14rem;
  }

  @media screen and (max-width: 767px) {
    height: 3.25rem;
    width: 13rem;
  }
`
// color: #362066;

const ButtonLinkOutlined = styled(Link)`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  border: 1px solid #466fa5;
  background: #fffff;
  color: #222222;
  font-weight: 500;
  min-height: 3rem;
  min-width: 3rem;
  padding: 1.4rem 1.4rem;
  border-radius: 40px;
  letter-spacing: 0.25px;

  :hover {
    background: #fcfaff;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
  }
`
//border: 1px solid #663399;
//color: var(--theme-ui-colors-white, #ffffff);

const ButtonLinkArrowed = styled(Link)`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  color: #1b5998;
  font-weight: 500;
  min-height: calc(2rem + 0.25rem);
  min-width: calc(2rem + 0.25rem);
  padding: 0.25rem 1rem;
  border-radius: 8px;
  position: relative;
  background: var(--theme-ui-colors-white, #ffffff);
  margin: auto;
  z-index: 1;
  letter-spacing: 0.25px;

  :hover {
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s linear;
  }
  :hover #Right-Arrow-Button {
    animation: bounceRight infinite 3s;
  }

  @media screen and (max-width: 1024px) {
    #Right-Arrow-Button {
      animation: bounceRight infinite 3s;
    }
  }

  @keyframes bounceRight {
    0% {
      transform: translateX(0%);
    }
    25% {
      transform: translateX(15%);
    }

    50% {
      transform: translateX(0%);
    }

    75% {
      transform: translateX(15%);
    }
  }
`

const ButtonSignup = styled(Link)`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  transition: background 0.5s, border 0.5s, color 0.5s;
  line-height: 1;
  text-decoration: none;
  background: #e8ebf7;
  color: #222222;
  font-weight: 600;
  min-height: 3rem;
  min-width: 3rem;
  padding: 1.4rem 1.4rem;
  border-radius: 40px;
  letter-spacing: 0.25px;
`

const ButtonHover = styled.button`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 1px solid transparent;
  color: #fcfdfd;
  min-height: 2rem;
  min-width: 2rem;
  padding: 0.25rem 0.75rem;
  color: var(--theme-ui-colors-grey-70, #48434f);
  letter-spacing: 0.25px;

  :hover {
    background: #fcfaff;
    color: #362066;
    transition: all 0.5s;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
  }
`

const ButtonNormal = styled.button`
  align-items: center;
  border: var(--theme-ui-colors-grey-60, #635e69);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  transition: background 0.5s, border 0.5s, color 0.5s;
  line-height: 1;
  text-decoration: none;
  background: #317ac3;
  color: #fcfdfd;
  font-weight: 500;
  min-height: 3rem;
  min-width: 3rem;
  width: 14.375rem;
  padding: 1.4rem 1.4rem;
  border-radius: 40px;
  letter-spacing: 0.25px;

  :hover {
    font-weight: 600;
    background: #1b5998;
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
  }

  @media screen and (max-width: 767px) {
  }
`

//display: none;

export {
  ButtonLink,
  ButtonLinkOutlined,
  ButtonLinkArrowed,
  ButtonSignup,
  ButtonHover,
  ButtonNormal,
}
